// ** react imports
import { ElementType, Fragment } from 'react'

// ** react router imports
import { Link, useLocation } from 'react-router-dom'

// ** redux & store imports
import { useSelector } from 'react-redux'
import { RootState } from 'src/store'

// ** mui imports
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import List from '@mui/material/List'
import { styled } from '@mui/material/styles'
import ListItemIcon from '@mui/material/ListItemIcon'
import MuiListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton'

// ** custom components imports
import AclGuard from 'src/@core/components/auth/AclGuard'
import UserIcon from 'src/layouts/components/UserIcon'
import Translations from 'src/layouts/components/Translations'
import CanViewNavLink from 'src/layouts/components/acl/CanViewNavLink'
import Typography from 'src/components/Typography'

// ** third party imports
import clsx from 'clsx'

// ** theme config imports
import themeConfig from 'src/configs/themeConfig'

// ** types imports
import { NavLink } from 'src/@core/layouts/types'
import { Settings } from 'src/@core/context/settingsContext'

// ** configs imports
import { Actions } from 'src/configs/acl'

// ** util import
import { hexToRGBA } from 'src/@core/utils/hex-to-rgba'

interface Props {
    item: NavLink
    settings: Settings
    hasParent: boolean
}

const ListItem = styled(MuiListItemButton)<
    ListItemButtonProps & { component?: ElementType; target?: '_blank' | undefined }
>(({ theme }) => ({
    width: 'auto',
    paddingTop: theme.spacing(2.25),
    color: theme.palette.text.primary,
    paddingBottom: theme.spacing(2.25),
    '&:hover': {
        backgroundColor: theme.palette.action.hover
    },
    '&.active, &.active:hover': {
        backgroundColor: hexToRGBA(theme.palette.primary.main, 0.08)
    },
    '&.active .MuiTypography-root, &.active .MuiListItemIcon-root': {
        color: theme.palette.primary.main
    }
}))

const HorizontalNavLink = (props: Props) => {
    // ** props
    const { item, settings, hasParent } = props

    // ** hook & vars
    const { user } = useSelector((state: RootState) => state.auth.auth)
    const { pathname } = useLocation()
    const { navSubItemIcon, menuTextTruncate } = themeConfig

    const IconTag = item.icon ? item.icon : navSubItemIcon

    const Wrapper = !hasParent ? List : Fragment

    const isNavLinkActive = () => {
        const exactCheck = user?.role == 'teacher' || user?.role == 'student' || user?.role == 'b2b'

        if (
            exactCheck
                ? pathname?.startsWith(item.path!)
                : item.path?.includes(pathname.split('/').filter(Boolean).at(0) as string)
        ) {
            return true
        }

        return false
    }

    return (
        <AclGuard aclAbilities={{ action: item.action! as Actions, subject: item.subject! }}>
            <CanViewNavLink navLink={item}>
                <Wrapper
                    {...(!hasParent
                        ? { component: 'div', sx: { py: settings.skin === 'bordered' ? 2.625 : 2.75 } }
                        : {})}
                >
                    <Link
                        to={`${item.path}`}
                        onClick={(e) => {
                            if (item.disabled) {
                                e.preventDefault()
                                e.stopPropagation()
                            }
                        }}
                        style={{ textDecoration: 'none' }}
                    >
                        <ListItem
                            component={'div'}
                            disabled={item.disabled}
                            className={clsx({ active: isNavLinkActive() })}
                            target={item.openInNewTab ? '_blank' : undefined}
                            sx={{
                                minWidth: 'max-content',
                                ...(item.disabled ? { pointerEvents: 'none' } : { cursor: 'pointer' }),
                                ...(!hasParent
                                    ? {
                                          borderRadius: '8px',
                                          '&.active, &.active:hover': {
                                              backgroundColor: (theme) => theme.palette.primary.main,
                                              '& .MuiTypography-root, & .MuiListItemIcon-root': {
                                                  color: 'common.white'
                                              }
                                          }
                                      }
                                    : {})
                            }}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        ...(menuTextTruncate && { overflow: 'hidden' })
                                    }}
                                >
                                    <ListItemIcon sx={{ mr: hasParent ? 3 : 2.5, color: 'text.primary' }}>
                                        <UserIcon
                                            icon={IconTag}
                                            componentType='horizontal-menu'
                                            {...(!isNavLinkActive() && { color: '#1A1A1A' })}
                                            {...(IconTag === navSubItemIcon
                                                ? { fontSize: '0.5rem' }
                                                : { fontSize: '1.5rem' })}
                                        />
                                    </ListItemIcon>
                                    <Typography
                                        variant='subtitle2'
                                        sx={{
                                            color: (theme) => theme.palette.grey.A200,
                                            minWidth: 'max-content'
                                        }}
                                        {...(menuTextTruncate && { noWrap: true })}
                                    >
                                        <Translations text={item.title} />
                                    </Typography>
                                </Box>
                                {item.badgeContent ? (
                                    <Chip
                                        size='small'
                                        label={item.badgeContent}
                                        color={item.badgeColor || 'primary'}
                                        sx={{
                                            ml: 1.5,
                                            '& .MuiChip-label': {
                                                px: 2.5,
                                                lineHeight: 1.385,
                                                textTransform: 'capitalize'
                                            }
                                        }}
                                    />
                                ) : null}
                            </Box>
                        </ListItem>
                    </Link>
                </Wrapper>
            </CanViewNavLink>
        </AclGuard>
    )
}

export default HorizontalNavLink
