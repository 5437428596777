// ** typedjson imports
import { jsonArrayMember, jsonMember, jsonObject } from 'typedjson'

// ** models imports
import { PlanModel } from './plan.model'

type UserLeadPlan = Pick<PlanModel, 'id' | 'priority'>
type UserEnrichmentPlan = Pick<PlanModel, 'id' | 'priority'>
type UserAcademicPlan = Pick<PlanModel, 'id' | 'priority'>
export enum UserRoleType {
    INDUSTRIAL = 'industrial',
    ACADEMIC = 'academic',
    B2B = 'b2b'
}

export type UserRole =
    | 'marketing specialist'
    | 'software engineer/developer'
    | 'sales representative'
    | 'manager'
    | 'freelancer'
    | 'teacher'
    | 'student'
    | 'b2b'

export type UserPermission = {
    permission: string
    type: string
    engine: string
    properties: Array<string>
}

@jsonObject
export class UserModel {
    @jsonMember(String)
    firstName: string

    @jsonMember(String)
    lastName: string

    @jsonMember(String)
    email: string

    @jsonMember(String)
    token: string

    @jsonMember(String)
    role: UserRole

    @jsonMember(String)
    intercom_hash: string

    @jsonMember(String)
    analytics_user_id: string

    @jsonMember(String)
    api_key: string

    @jsonMember(String)
    created_at: string

    @jsonMember(Object)
    lead_plan: UserLeadPlan

    @jsonMember(Object)
    enrichment_plan: UserEnrichmentPlan

    @jsonMember(Object)
    academic_plan: UserAcademicPlan

    @jsonArrayMember(Object)
    permissions: Array<UserPermission>
}
