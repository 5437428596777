// ** react imports
import { useState, SyntheticEvent, Fragment, useMemo, useEffect } from 'react'

// ** react router imports
import { Link, useNavigate } from 'react-router-dom'

// ** mui imports
import Stack from '@mui/material/Stack'
import Menu from '@mui/material/Menu'
import Badge from '@mui/material/Badge'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'

// ** redux & store imports
import { useDispatch, useSelector } from 'react-redux'
import type { AppDispatch, RootState } from 'src/store'
import { logout } from 'src/store/features/auth'

// ** custom components imports
import Icon from 'src/@core/components/icon'
import CompanyLogo from 'src/components/CompanyLogo'
import Image from 'src/components/Image'

// ** third party imports
import { useMutation, useQuery } from '@tanstack/react-query'

// ** services imports
import { ResponseCode } from 'src/services/base/error_handler'
import CommonService from 'src/services/common/Common.service'

// ** models imports
import { UserModel } from 'src/models'

// ** type imports
import { Settings } from 'src/@core/context/settingsContext'

interface Props {
    settings: Settings
}

// ** styled components
const BadgeContentSpan = styled('span')(({ theme }) => ({
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: theme.palette.success.main,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`
}))
const styles = {
    py: 2,
    px: 4,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: 'text.primary',
    textDecoration: 'none',
    '& svg': {
        fontSize: '1.375rem'
    }
}

const commonApi = new CommonService()

const UserDropdown = (props: Props) => {
    // ** vars
    const { settings } = props
    const { direction } = settings

    // ** states
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)
    const [isValidLogo, setIsValidLogo] = useState<boolean>(false)
    const [companyImgSrc, setCompanyImgSrc] = useState<string>('')

    // ** hooks
    const user: UserModel | null = useSelector(({ auth }: RootState) => auth.auth.user)
    const navigate = useNavigate()
    const dispatch = useDispatch<AppDispatch>()

    const dropdownItems = useMemo(
        () => [
            {
                name: 'Profile',
                icon: 'mdi:account-outline',
                internalLink: '/profile',
                externalLink: null,
                canAccess: true
            },
            {
                name: 'Credits Usage',
                icon: 'bx:coin-stack',
                internalLink: '/credits-usage',
                externalLink: null,
                canAccess: user?.role != 'b2b'
            },
            {
                name: 'Billing',
                icon: 'mdi:currency-usd',
                internalLink: '/invoices',
                externalLink: null,
                canAccess: true
            },
            {
                name: 'Team Management',
                icon: 'ant-design:team-outlined',
                internalLink: '/teams',
                externalLink: null,
                canAccess: user?.role != 'teacher' && user?.role != 'student' && user?.role != 'b2b'
            },
            // {
            //     name: 'Campaign Management',
            //     icon: 'prime:megaphone',
            //     internalLink: '/campaigns',
            //     externalLink: null,
            //     canAccess: user?.role != 'teacher' && user?.role != 'student' && user?.role != 'b2b'
            // },
            {
                name: 'Integrations',
                icon: 'el:cogs',
                internalLink: '/integrations',
                externalLink: null,
                canAccess: user?.role != 'teacher' && user?.role != 'student' && user?.role != 'b2b'
            },
            {
                name: 'Tickets',
                icon: 'mdi:face-agent',
                internalLink: '/tickets',
                externalLink: null,
                canAccess: true
            },
            {
                name: 'CUFinder chrome extension',
                icon: 'ant-design:chrome-outlined',
                internalLink: null,
                externalLink:
                    'https://chrome.google.com/webstore/detail/cufinder-find-b2b-contact/kjockjfldkeagjfoaadjlahmakglagka',
                canAccess: true
            },
            {
                name: 'API documentation',
                icon: 'ant-design:api-twotone',
                internalLink: null,
                externalLink: 'https://apidoc.cufinder.io/',
                canAccess: true
            },
            {
                name: 'Knowledge center',
                icon: 'streamline:manual-book-solid',
                internalLink: null,
                externalLink: 'https://knowledge.cufinder.io/',
                canAccess: true
            }
        ],
        [user]
    )

    const userEmail = useMemo(() => {
        const email = user?.email || ''

        return {
            value: email,
            domain: email.split('@')?.[1] || ''
        }
    }, [user?.email])

    useEffect(() => {
        handleValidateUserEmail()
    }, [userEmail])

    // ** methods
    const handleDropdownOpen = (event: SyntheticEvent) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseDropdown = (url?: string) => {
        if (url) navigate(url)

        setAnchorEl(null)
    }

    const handleLogout = () => {
        commonApi.newEvent('user logged out')
        dispatch(logout())

        navigate('/auth/signin')
        handleCloseDropdown()
    }

    // get company logo
    const { isPending: avatarLoading, mutate: handleValidateUserEmail } = useMutation({
        mutationFn: () => commonApi.verifyEmail({ email: userEmail.value }, { hideDefaultToast: true }),
        onSuccess: (response) => {
            const emailValid = response?.code === ResponseCode.USER_EMAIL_VERIFIED

            if (emailValid) {
                handleLogoSetup()

                return
            }

            setIsValidLogo(false)
            setCompanyImgSrc('')
        }
    })

    const { mutate: handleLogoSetup } = useMutation({
        mutationFn: () =>
            commonApi.getCompanyLogo(
                { domain: userEmail.domain },
                {
                    transformResponse: (response, headers, statusCode) => {
                        let res
                        const contentType = headers['content-type']
                        try {
                            if (contentType.includes('application/json')) {
                                res = JSON.parse(response)

                                return {
                                    ...res,
                                    statusCode
                                }
                            } else {
                                res = response

                                return {
                                    res,
                                    statusCode
                                }
                            }
                        } catch (error) {
                            throw new Error(JSON.stringify(error))
                        }
                    }
                }
            ),
        onSuccess: (res) => {
            const logoValid = res?.statusCode === 200
            setIsValidLogo(logoValid)
            setCompanyImgSrc(logoValid ? `https://api.cufinder.io/logo/${userEmail.domain}` : '')
        }
    })

    return (
        <Fragment>
            {!avatarLoading && (
                <Stack onClick={handleDropdownOpen} sx={{ cursor: 'pointer' }}>
                    <Badge
                        overlap='circular'
                        sx={{ ml: 2, cursor: 'pointer' }}
                        badgeContent={<BadgeContentSpan />}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                    >
                        {isValidLogo && companyImgSrc ? (
                            <CompanyLogo companyLogo={companyImgSrc} size={40} onlyCompanyLogo />
                        ) : (
                            <Image
                                sx={{ width: 40, height: 40 }}
                                variant='circular'
                                src='/images/avatar.png'
                                alt={user?.firstName ? `${user.firstName} ${user.lastName}` : user?.lastName}
                            />
                        )}
                    </Badge>
                </Stack>
            )}

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => handleCloseDropdown()}
                sx={{ '& .MuiMenu-paper': { minWidth: 230, mt: 4 } }}
                anchorOrigin={{ vertical: 'bottom', horizontal: direction === 'ltr' ? 'right' : 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: direction === 'ltr' ? 'right' : 'left' }}
            >
                <Stack py={2} px={4}>
                    <Stack direction='row' alignItems='center' spacing={2}>
                        {isValidLogo && companyImgSrc ? (
                            <CompanyLogo companyLogo={companyImgSrc} size={40} onlyCompanyLogo />
                        ) : (
                            <Image
                                src='/images/avatar.png'
                                variant='circular'
                                sx={{ width: 40, height: 40 }}
                                alt={user?.firstName ? `${user.firstName} ${user.lastName}` : user?.lastName}
                            />
                        )}

                        <Typography
                            noWrap
                            fontWeight={600}
                            textTransform='capitalize'
                        >{`${user?.firstName || ''} ${user?.lastName}`}</Typography>
                    </Stack>
                </Stack>
                <Divider sx={{ m: 0 }} />
                {dropdownItems
                    .filter((item) => item.canAccess)
                    .map((item) =>
                        item.internalLink ? (
                            <MenuItem
                                key={item.name}
                                sx={{ p: 0 }}
                                onClick={() => handleCloseDropdown(item.internalLink)}
                            >
                                <Stack direction='row' spacing={2} sx={styles}>
                                    <Icon icon={item.icon} />
                                    <Typography>{item.name}</Typography>
                                </Stack>
                            </MenuItem>
                        ) : (
                            <Stack
                                key={item.name}
                                direction='row'
                                component={Link}
                                to={item.externalLink!}
                                target='_blank'
                                sx={styles}
                                spacing={2}
                            >
                                <Icon icon={item.icon} />
                                <Typography>{item.name}</Typography>
                            </Stack>
                        )
                    )}
                <Divider sx={{ m: 0 }} />
                <MenuItem sx={{ py: 2 }} onClick={handleLogout}>
                    <Stack direction='row' spacing={2}>
                        <Icon icon='mdi:logout-variant' />
                        <Typography>Logout</Typography>
                    </Stack>
                </MenuItem>
            </Menu>
        </Fragment>
    )
}

export default UserDropdown
