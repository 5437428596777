// ** typedjson imports
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'

@jsonObject
export class UniversityDegree {
    @jsonMember(Boolean)
    Bachelor: boolean

    @jsonMember(Boolean)
    'Diploma Associate Foundation': boolean

    @jsonMember(Boolean)
    Doctorate: boolean

    @jsonMember(Boolean)
    Master: boolean
}

interface UniversityDegreeTitle {
    [key: string]: UniversityDegree
}

@jsonObject
export class UniversityModel {
    @jsonMember(String)
    '2000 Classification': string

    @jsonMember(String)
    'Academic Calendar': string

    @jsonMember(String)
    'Academic Counseling': string

    @jsonMember(String)
    'Academic Staff': string

    @jsonMember(String)
    Address: string

    @jsonMember(String)
    'Admission Office': string

    @jsonMember(String)
    'Admission Rate': string

    @jsonMember(String)
    'Basic Classification': string

    @jsonMember(String)
    'Campus Setting': string

    @jsonMember(String)
    'Career Services': string

    @jsonMember(String)
    'Control Type': string

    @jsonMember(String)
    'Distance Learning': string

    @jsonMember(String)
    'Enrollment Profile': string

    @jsonMember(String)
    'Entity Type': string

    @jsonMember(String)
    Fax: string | null

    @jsonMember(String)
    'Financial Aids': string

    @jsonMember(String)
    Gender: string

    @jsonMember(String)
    'Graduate Instructional Program': string

    @jsonMember(String)
    Housing: string

    @jsonMember(String)
    'Institutional Hospital': string

    @jsonMember(String)
    'International Students': string

    @jsonMember(String)
    'International students_Postgraduate': string

    @jsonMember(String)
    'International students_Undergraduate': string

    @jsonMember(String)
    Library: string

    @jsonMember(String)
    'Local students_Postgraduate': string

    @jsonMember(String)
    'Local students_Undergraduate': string

    @jsonMember(String)
    Name: string

    @jsonMember(String)
    'New Admission Requirements': string

    @jsonMember(String)
    'Religious Affiliation': string

    @jsonMember(String)
    'Selection Type': string

    @jsonMember(String)
    'Size & Setting': string

    @jsonMember(String)
    'Sport Facilities': string

    @jsonMember(String)
    'Student Enrollment': string

    @jsonMember(String)
    'Study Abroad': string

    @jsonMember(Object)
    'Study_levels and Degree levels': UniversityDegreeTitle

    @jsonMember(String)
    Tel: string | null

    @jsonMember(String)
    'Undergraduate Instructional Program': string | null

    @jsonMember(String)
    'Undergraduate Profile': string | null

    @jsonMember(String)
    _id: string

    @jsonMember(String)
    continent: string | null

    @jsonMember(String)
    country: string | null

    @jsonMember(String)
    country_code: string | null

    @jsonMember(Number)
    country_ranking: number | null

    @jsonMember(String)
    description_wikipedia: string | null

    @jsonMember(String)
    facebook_page: string | null

    @jsonMember(String)
    google_scholar_page: string | null

    @jsonMember(String)
    instagram_page: string | null

    @jsonMember(String)
    linkedin_page: string | null

    @jsonMember(String)
    logo_url: string | null

    @jsonArrayMember(String)
    membership_list: Array<string>

    @jsonMember(String)
    org_code: string | null

    @jsonMember(String)
    overview: string | null

    @jsonMember(Boolean)
    scholar_page_tag: boolean

    @jsonMember(Boolean)
    shcolarshipdb_tag: boolean

    @jsonMember(String)
    twitter_page: string | null

    @jsonMember(String)
    university_domain: string | null

    @jsonMember(String)
    website: string | null

    @jsonMember(String)
    wikipedia_page_2: string | null

    @jsonMember(String)
    wikipeida_page: string | null

    @jsonMember(Number)
    world_ranking: number | null

    @jsonMember(String)
    youtube_page: string | null

    @jsonMember(Boolean)
    exists: boolean

    @jsonMember(Boolean)
    has_fax: boolean

    @jsonMember(Boolean)
    has_tel: boolean
}
