// ** react imports
import { FC, useEffect } from 'react'

// ** react router imports
import { Outlet, useLocation, useMatches } from 'react-router-dom'

// ** mui imports
import { Theme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

// ** store imports
import { store, useAppDispatch } from 'src/store'
import { updateUser } from 'src/store/features/auth'

// ** socket imports
import * as plansSocket from 'src/services/socket/plans.socket'

// ** layouts imports
// !Do not remove this Layout import
import Layout from 'src/@core/layouts/Layout'
import AuthGuard from 'src/@core/components/auth/AuthGuard'

// ** navigation imports
import VerticalNavItems from 'src/navigation/vertical'
import HorizontalNavItems from 'src/navigation/horizontal'

import VerticalAppBarContent from './components/vertical/AppBarContent'
import HorizontalAppBarContent from './components/horizontal/AppBarContent'

// ** hook import
import { useSettings } from 'src/@core/hooks/useSettings'
import { useIntercom } from 'react-use-intercom'

// ** types imports
import { ThemeColor } from 'src/@core/layouts/types'

type EngineTheme = {
    name: string
    color: ThemeColor
}

const enginesThemeColors: Array<EngineTheme> = [
    { name: 'academic-engine-group', color: 'academic_engine' },
    { name: 'enrichment-engine-group', color: 'enrichment_engine' },
    { name: 'hr-engine-screen', color: 'hr_engine' },
    { name: 'prospect-engine-group', color: 'prospect_engine' },
    { name: 'business-directory-group', color: 'business_directory_engine' }
]

const UserLayout: FC = () => {
    // ** hooks
    const dispatch = useAppDispatch()
    const { settings, saveSettings } = useSettings()
    const { update } = useIntercom()
    const location = useLocation()
    const [, , groupRoute] = useMatches()

    store.subscribe(() => {
        const { user } = store.getState().auth.auth

        update({
            name: user?.firstName ? `${user.firstName} ${user.lastName}` : user?.lastName,
            email: user?.email,
            userHash: user?.intercom_hash,
            createdAt: user?.created_at
        })
    })

    // setup theme color based on engine theme
    useEffect(() => {
        setupTheme()

        plansSocket.connect().subscribe((userData) => {
            if (userData?.permissions && userData.permissions?.length > 0) dispatch(updateUser({ ...userData }))
        })

        return () => {
            plansSocket.disconnect()
        }
    }, [])

    useEffect(() => {
        setupTheme()
    }, [location])

    const setupTheme = () => {
        const themeColor = enginesThemeColors.filter((item) => item.name == groupRoute.id).at(0)?.color || 'primary'

        if (settings.themeColor != themeColor) saveSettings({ themeColor: themeColor })
    }

    /**
     *  The below variable will hide the current layout menu at given screen size.
     *  The menu will be accessible from the Hamburger icon only (Vertical Overlay Menu).
     *  You can change the screen size from which you want to hide the current layout menu.
     *  Please refer useMediaQuery() hook: https://mui.com/material-ui/react-use-media-query/,
     *  to know more about what values can be passed to this hook.
     *  ! Do not change this value unless you know what you are doing. It can break the template.
     */
    const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

    return (
        <AuthGuard>
            <Layout
                hidden={hidden}
                settings={settings}
                saveSettings={saveSettings}
                {...(settings.layout === 'horizontal'
                    ? {
                          // ** Navigation Items
                          horizontalNavItems: HorizontalNavItems(),

                          // Uncomment the below line when using server-side menu in horizontal layout and comment the above line
                          // horizontalNavItems: ServerSideHorizontalNavItems(),

                          // ** AppBar Content
                          horizontalAppBarContent: () => (
                              <HorizontalAppBarContent
                                  hidden={hidden}
                                  settings={settings}
                                  saveSettings={saveSettings}
                              />
                          )
                      }
                    : {
                          // ** Navigation Items
                          verticalNavItems: VerticalNavItems(),

                          // Uncomment the below line when using server-side menu in vertical layout and comment the above line
                          // verticalNavItems: ServerSideVerticalNavItems(),

                          // ** AppBar Content
                          verticalAppBarContent: (props) => (
                              <VerticalAppBarContent
                                  hidden={hidden}
                                  settings={settings}
                                  saveSettings={saveSettings}
                                  toggleNavVisibility={props.toggleNavVisibility}
                              />
                          )
                      })}
            >
                <Outlet />
            </Layout>
        </AuthGuard>
    )
}

export default UserLayout
