// ** typedjson imports
import { jsonObject, jsonMember, jsonArrayMember } from 'typedjson'

@jsonObject
export class CitationHistoryModel {
    @jsonMember(String)
    value: string | null

    @jsonMember(String)
    year: string | null
}

@jsonObject
export class CoAuthorModel {
    @jsonMember(String)
    affiliation: string | null

    @jsonMember(String)
    name: string | null

    @jsonMember(String)
    user_code: string | null
}

@jsonObject
export class ProfessorModel {
    @jsonMember(String)
    _id: string

    @jsonMember(String)
    affiliation: string

    @jsonMember(String)
    continent: string

    @jsonMember(String)
    country: string

    @jsonMember(Number)
    'h-index_All': number

    @jsonMember(String)
    name: string

    @jsonMember(String)
    position: string

    @jsonMember(String)
    university: string

    @jsonMember(String)
    university_id_mongo: string | null

    @jsonMember(Number)
    university_country_ranking: number

    @jsonMember(String)
    university_url: string

    @jsonMember(Number)
    university_world_ranking: number

    @jsonMember(String)
    Citations_All: string | null

    @jsonMember(Number)
    Citations_All_value: number | null

    @jsonMember(String)
    'Citations_Since 2016': string | null

    @jsonMember(Number)
    Citations_Since_2016_value: number | null

    @jsonMember(String)
    Cited_by: string | null

    @jsonMember(Number)
    Cited_by_value: number | null

    @jsonMember(String)
    Homepage: string | null

    @jsonArrayMember(CitationHistoryModel)
    citation_year_history: Array<CitationHistoryModel>

    @jsonArrayMember(CitationHistoryModel)
    co_authors: Array<CoAuthorModel>

    @jsonArrayMember(String)
    current_verified_email: string | null

    @jsonArrayMember(Boolean)
    has_verified_email: boolean

    @jsonArrayMember(String)
    email: Array<string>

    @jsonArrayMember(String)
    email_verify: Array<string>

    @jsonArrayMember(String)
    emails_generated_verify: Array<string>

    @jsonArrayMember(String)
    google_scholar_url: Array<string>

    @jsonMember(String)
    'h-index_Since 2016': string | null

    @jsonMember(Number)
    h_index_All_value: number | null

    @jsonMember(Number)
    h_index_Since_2016_value: number | null

    @jsonMember(Boolean)
    has_email: boolean

    @jsonMember(String)
    'i10-index_All': string | null

    @jsonMember(String)
    'i10-index_Since 2016': string | null

    @jsonMember(Number)
    i10_index_All_value: number | null

    @jsonMember(Number)
    i10_index_Since_2016_value: number | null

    @jsonMember(Boolean)
    process_tag: boolean

    @jsonMember(String)
    profile_image: string | null

    @jsonMember(Boolean)
    publication_tag: boolean

    @jsonMember(Boolean)
    publication_tag_2: boolean

    @jsonMember(Boolean)
    publication_tag_3: boolean

    @jsonArrayMember(String)
    research_interests: Array<string>

    @jsonMember(String)
    university_Academic_Calendar: string | null

    @jsonMember(String)
    university_Academic_Counseling: string | null

    @jsonMember(String)
    university_Academic_Staff: string | null

    @jsonMember(String)
    university_Address: string | null

    @jsonMember(String)
    university_Admission_Office: string | null

    @jsonMember(String)
    university_Admission_Rate: string | null

    @jsonMember(String)
    university_Campus_Setting: string | null

    @jsonMember(String)
    university_Career_Services: string | null

    @jsonMember(String)
    university_Control_Type: string | null

    @jsonMember(String)
    university_Distance_Learning: string | null

    @jsonMember(String)
    university_Entity_Type: string | null

    @jsonMember(String)
    university_Fax: string | null

    @jsonMember(String)
    university_Financial_Aids: string | null

    @jsonMember(String)
    university_Gender: string | null

    @jsonMember(String)
    university_Housing: string | null

    @jsonMember(String)
    university_Institutional_Hospital: string | null

    @jsonMember(String)
    university_International_Students: string | null

    @jsonMember(String)
    university_International_students_Postgraduate: string | null

    @jsonMember(String)
    university_International_students_Undergraduate: string | null

    @jsonMember(String)
    university_Library: string | null

    @jsonMember(String)
    university_Local_students_Postgraduate: string | null

    @jsonMember(String)
    university_Local_students_Undergraduate: string | null

    @jsonMember(String)
    university_Religious_Affiliation: string | null

    @jsonMember(String)
    university_Selection_Type: string | null

    @jsonMember(String)
    university_Sport_Facilities: string | null

    @jsonMember(String)
    university_Student_Enrollment: string | null

    @jsonMember(String)
    university_Study_Abroad: string | null

    @jsonMember(String)
    university_Study_levels_and_Degree_levels: string | null

    @jsonMember(String)
    university_Tel: string | null

    @jsonMember(String)
    university_continent: string | null

    @jsonMember(String)
    university_country: string | null

    @jsonMember(String)
    university_country_code: string | null

    @jsonMember(Number)
    university_country_ranking_value: number | null

    @jsonMember(String)
    university_description_wikipedia: string | null

    @jsonMember(String)
    university_domain: string | null

    @jsonMember(String)
    university_facebook_page: string | null

    @jsonMember(String)
    university_google_scholar_page: string | null

    @jsonMember(String)
    university_instagram_page: string | null

    @jsonMember(String)
    university_linkedin_page: string | null

    @jsonMember(String)
    university_logo_url: string | null

    @jsonArrayMember(String)
    university_membership_list: Array<string>

    @jsonMember(String)
    university_org_code: string | null

    @jsonMember(String)
    university_overview: string | null

    @jsonMember(String)
    university_twitter_page: string | null

    @jsonMember(String)
    university_website: string | null

    @jsonMember(String)
    university_wikipedia_page_2: string | null

    @jsonMember(String)
    university_wikipeida_page: string | null

    @jsonMember(Number)
    university_world_ranking_value: number | null

    @jsonMember(String)
    university_youtube_page: string | null

    @jsonMember(Boolean)
    url_get_status: boolean

    @jsonMember(String)
    user_code: string

    @jsonMember(String)
    verify_homepage: string | null

    @jsonMember(Boolean)
    exists: boolean
}
