// ** typedjson imports
import { jsonMember, jsonObject } from 'typedjson'

@jsonObject
export class ProfessorCountryModel {
    @jsonMember(String)
    name: string

    @jsonMember(String)
    code: string
}
