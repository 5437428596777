// ** store imports
import { store } from 'src/store'

// ** socket imports
import socketManager from './manager'

// ** third party imports
import { BehaviorSubject } from 'rxjs'

// ** models imports
import { UserModel } from 'src/models'

// ** configs imports
import authConfigs from 'src/configs/auth'

type PermissionsSocketResponseModel = Pick<UserModel, 'lead_plan' | 'enrichment_plan' | 'academic_plan' | 'permissions'>

const socket = socketManager.socket('/plans', {
    auth: (callback) => {
        const token = localStorage.getItem(authConfigs.storageTokenKeyName)

        callback({ token })
    }
})

export const connect = () => {
    const user = store.getState().auth.auth.user
    const newUserData = new BehaviorSubject<PermissionsSocketResponseModel | null>(null)

    socket.connect()
    socket.on('connect', () => {})

    socket.on('upgrade_alert', (data) => {
        const email = data.em

        if (user?.email == email) {
            socket.emit('permissions', (data: PermissionsSocketResponseModel) => {
                newUserData.next(data)
            })
        }
    })

    return newUserData
}

export const disconnect = () => {
    socket.disconnect()
}
