// ** typedjson imports
import { jsonMember, jsonObject } from 'typedjson'

// ** models imports
import { UserModel } from './user.model'

export enum CustomPlanType {
    MONTHLY = 'monthly',
    YEARLY = 'yearly'
}

export enum CustomPlanEngine {
    LEAD = 'lead',
    ENRICHMENT = 'enrichment',
    ACADEMIC = 'academic'
}

@jsonObject
export class CustomPlanModel {
    @jsonMember(Number)
    id: number

    @jsonMember(UserModel)
    user: Partial<UserModel>

    @jsonMember(String)
    name: string

    @jsonMember(Number)
    price: number

    @jsonMember(Number)
    credits: number

    @jsonMember(Number)
    fair_credits: number | null

    @jsonMember(Number)
    mobile_credits: number | null

    @jsonMember(String)
    type: CustomPlanType

    @jsonMember(String)
    engine: CustomPlanEngine

    @jsonMember(String)
    price_id: string

    @jsonMember(Date)
    created_at: Date

    @jsonMember(Date)
    updated_at: Date

    @jsonMember(Date)
    deleted_at: Date | null
}
